import { mapActions, mapState } from "vuex";
import { showError } from "@/helpers/globalHelpers";
export default {
    name: "DetailPlanType",
    components: {
    },
    data() {
        return {
            tab: 0,
            isLoadingPlanType: false,
            profileHeaders: [
                { text: "Perfil Asociado", sortable: false, value: "profile.name" }
            ],
            customFieldHeaders:[
                { text: "Nombre de campo", sortable: false, value: "field_name" },
                { text: "Tipo de dato", sortable: false, value: "data_type" },
                { text: "Requerido", sortable: false, value: "is_required" }
            ],
            groupingHeaders:[
                { text: "Cabecera - Valor", sortable: false, value: "field_groups" },
                { text: "Servicios", sortable: false, value: "services" },
              ],
              additionalFieldsHeaders: [
                { text: "Nombre del Campo", value: "field_name" },
                { text: "Tipo", value: "type" },
                { text: "Descripción", value: "description" },
                { text: "Valores", value: "listValues" },
              ],
              additionalFields: [],
        };
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: "Inicio",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: "Dashboard",
                    },
                },
                {
                    text: "Tipos de planes de acción",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: "ListPlanType",
                    },
                },
                {
                    text: "Detalle de tipo de plan de acción",
                    link: true,
                    exact: true,
                    disabled: true,
                    // to: {
                    //   name: 'InventoryHome',
                    // },
                },
            ];
        },
        ...mapState("plan_type", ["planType"]),
        flags() {
            if(this.planType){
            return Object.fromEntries(
                Object.entries(this.planType).filter(([key, value]) => key.startsWith('flag') && typeof value === 'boolean')
              );
            }
          },
    },
    methods: {
        ...mapActions('plan_type', ['getPlantypeById']),

        async getPlanType() {
            this.isLoadingPlanType = true;
            const { planTypeId } = this.$route.params;
            const { error } = await this.getPlantypeById(planTypeId)
            if (error) {
                showError(error);
                this.isLoadingPlanType = false;
                return;
              }       

            // Procesar los campos adicionales para mostrarlos en la tabla
      if (this.planType.fields) {
        this.additionalFields = this.planType.fields.map((field) => ({
          id: field.id,
          field_name: field.field_name,
          type: field.field_type,
          description: field.description || "N/A",
          listValues:
            field.field_type === "LISTA DESPLEGABLE"
              ? JSON.parse(field.values || "[]")
              : [],
        }));
      }


            this.isLoadingPlanType = false;
        },

        translateFlag(key) {
            const translations = {
              flag_action_plan_name: "Permitir nombre de plan de acción",
              flag_responsibles: "Permitir responsables",
              flag_service_selection: "Permitir selección de servicios",
              flag_notify_users: "Permitir usuarios notificadores",
              flag_multiple_dates: "Permitir múltiples fechas",
              flag_required_additional_fields: "Permitir campos adicionales"
            };
            return translations[key] || key;
          },
    },
    created() {
        this.getPlanType();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
};